import { createGlobalStyle } from 'styled-components';
import { normalize } from 'styled-normalize';

import { breakpoints, toRem } from 'utils/mixins';
import { black, blue, grey, white } from './colors';
import { container, space } from './sizes';
import { p, h1, h2, h3, h4 } from './typography';

export const GlobalStyles = createGlobalStyle`
  ${normalize}

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  html {
    font-size: 62.5%;
  }

  b, strong {
    font-weight: bold;
  }

  .popup-image {
    border-radius: ${toRem(24)};
    overflow: hidden;
    margin: ${toRem(16)} 0;
    max-width: 100%;
    aspect-ratio: 1.33;
    height: 100%;
    width: 100%;
    flex: 1;

    & > div, img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }
  
  html,
  body {
    margin: 0;
    padding: 0;
    background: ${grey.default};
    word-wrap: break-word;
  }

  body {
    background-attachment: fixed;
    visibility: hidden;
    opacity: 0;
    overflow-y:hidden;
    font-family: "Open Sans", sans-serif;
    font-weight: 300;
    line-height: 1.4;
    -webkit-font-smoothing: antialiased;
    color: black;
    -moz-osx-font-smoothing: grayscale;
    cursor: default;

    &.noover {
      overflow: hidden;
    }
  }

  .main {
    min-height: calc(100vh - 182px);
    position: relative;
    margin-top: 71px;

    @media ${breakpoints.laptop} {
      min-height: calc(100vh - 196px);
      margin-top: 119px;
    }
    
    &--black {
      background-color: ${grey.darker};
      p {
        color: ${white.default};
      }
    }
  }
  
  .container {
    width: 100%;
    max-width: ${container.default};
    margin: 0 auto;
    position: relative;
    padding: ${space.m} ${space.s};
    &--small {
      max-width: ${container.small};
    }
  }

  .whatsapp_widget_input{
    font-size: 13px!important;
    line-height: 13px!important;
    
    @supports (-webkit-touch-callout: none) {
      /* CSS specific to iOS devices */ 
      font-size: 16px!important;
      line-height: 16px!important;
    }
  }

  input {@supports (-webkit-touch-callout: none) {
      /* CSS specific to iOS devices */ 
      font-size: 16px!important;
      line-height: 16px!important;
    }
  }

  .flex {
    margin-bottom: ${toRem(80)};

    .gatsby-image-wrapper{
      width: 100%;
    }

    .map{
      padding: ${toRem(40)} 0 0 0;
      min-height: ${toRem(600)};
      align-self: stretch;
      
      iframe {
        border: 0;
        min-height: ${toRem(600)};
      }
    }

    @media ${breakpoints.laptop} {
      display: flex;
      //margin-bottom: ${toRem(150)};

      .gatsby-image-wrapper{
        width: 50%;
      }

      .map{
        padding: ${toRem(20)};
        width: 50%;
        align-self: stretch;
      }
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    font-weight: 400;
  }

  h1, .h1 {
    ${h1}
    color: black;
    transition: 250ms;
  }

  h2 {
    ${h2}
    color: black;
    transition: 250ms;
  }

  h3 {
    ${h3}
  }

  h4{
    ${h4}
  }

  p, label {
    ${p}
    margin-bottom: 1em;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  img {
    max-width: 100%;
  }

  a { 
    ${h3};
    position: relative;
    font-weight: 300;
    color: ${white.default};
    color: inherit;
    text-decoration: none;
    transition: 250ms;
    span {
      font-weight: bold;
    }
  }

  button {
    appearance: none;
    display: inline-block;
    border-radius: 0;
    background: none;
    outline: none;
    box-shadow: none;
    border: none;
    padding: 0;
    color: inherit;
    font-size: inherit;
    font-family: inherit;
    cursor: pointer;
    transition: 0.3s;
  }

  address {
    font-style: normal;
  }

  .show {
    display: block;
  }

  .hidden {
    display: none;
  }

  .hide__mobile{
    @media (max-width: 1079px) {
      display: none;
    }
  }

  .show__mobile{
    display: none;
    @media (max-width: 1079px) {
      display: block;
    }
  }

  .button {
    ${h4}
    color: ${black.default};
    border: 1px solid black;
    padding: ${toRem(15)} ${toRem(20)};
    padding: ${space.xxs} ${space.xs};
    text-transform: uppercase;

    &--small {
      ${p}
      padding: ${toRem(6)} ${toRem(10)};
    }

    &--secondary {
      ${p}
      font-size: ${toRem(11)};
      padding: ${toRem(6)};
      border: none !important;
    }

    @media ${breakpoints.laptop} {
      padding: ${toRem(15)} ${toRem(20)};
    }
  }
`;
