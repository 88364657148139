import styled from 'styled-components';

import { white, black, grey, blue, red } from 'theme/colors';
import { toRem, breakpoints } from 'utils/mixins';
import { space, zIndex } from 'theme/sizes';

export const Wrapper = styled.header`
  position: fixed;
  width: 100%;
  padding: 0 ${space.s};
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  //z-index: 20;
  ${zIndex.top};
  height: 71px;
  transition: 150ms;
  background-color: ${white.darker};
  border-bottom: 1px solid ${white.darker};

  @media ${breakpoints.laptop} {
    justify-content: unset;
  }
`;

export const Logo = styled.div`
  position: relative;
  //z-index: 21;
  ${zIndex.extreme};

  a {
    line-height: 1;
    font-family: 'Gilda Display', serif;
    color: ${black.default};
  }

  img {
    width: ${space.xxl};
  }
`;

export const Toggle = styled.button`
  position: relative;
  cursor: pointer;
  //z-index: 21;
  ${zIndex.top};
  width: ${toRem(20)};
  height: ${toRem(16)};
  display: block;

  .span {
    top: ${toRem(7)};
    background-color: ${black.default};
    width: ${toRem(20)};
    height: ${toRem(2)};
    display: block;
    position: absolute;

    &--top {
      position: absolute;
      top: 0;
    }

    &--bottom {
      position: absolute;
      top: ${toRem(14)};
    }
  }

  @media ${breakpoints.laptop} {
    display: none;
  }
`;
