import styled from 'styled-components';

import { p, h4 } from 'theme/typography';
import { breakpoints, toRem } from 'utils/mixins';
import { black, white } from 'theme/colors';

export const CookieContainer = styled.div`
  width: 100%;
  z-index: 5;
  background-color: rgba(0, 0, 0, 0.75);
  position: fixed;
  bottom: 0;
  padding: ${toRem(10)};

  .container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
    color: ${white.default};

    p {
      max-width: 100%;
      text-align: left;
    }

    a {
      ${p}
    }

    button {
      ${h4}
      background-color: ${white.default};
      color: ${black.default};
      padding: ${toRem(10)} ${toRem(15)};
      text-transform: uppercase;
    }
  }

  @media ${breakpoints.laptop} {
    .container {
      flex-wrap: nowrap;
      justify-content: center;
      justify-content: flex-start;
      p {
        max-width: calc(100% - 300px);
        padding: ${toRem(26)};
      }
    }
  }
`;
