exports.components = {
  "component---src-templates-custom-contacts-template-js": () => import("./../../../src/templates/custom/ContactsTemplate.js" /* webpackChunkName: "component---src-templates-custom-contacts-template-js" */),
  "component---src-templates-custom-fabrica-template-js": () => import("./../../../src/templates/custom/FabricaTemplate.js" /* webpackChunkName: "component---src-templates-custom-fabrica-template-js" */),
  "component---src-templates-custom-homepage-template-js": () => import("./../../../src/templates/custom/HomepageTemplate.js" /* webpackChunkName: "component---src-templates-custom-homepage-template-js" */),
  "component---src-templates-custom-privacy-template-js": () => import("./../../../src/templates/custom/PrivacyTemplate.js" /* webpackChunkName: "component---src-templates-custom-privacy-template-js" */),
  "component---src-templates-custom-produtos-template-js": () => import("./../../../src/templates/custom/ProdutosTemplate.js" /* webpackChunkName: "component---src-templates-custom-produtos-template-js" */),
  "component---src-templates-general-enchimentos-template-js": () => import("./../../../src/templates/general/EnchimentosTemplate.js" /* webpackChunkName: "component---src-templates-general-enchimentos-template-js" */),
  "component---src-templates-general-produto-js": () => import("./../../../src/templates/general/Produto.js" /* webpackChunkName: "component---src-templates-general-produto-js" */)
}

