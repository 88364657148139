import React from "react";

import { useCookies } from "react-cookie";
import Link from "gatsby-link";

import copy from "data/copy";

import { CookieContainer } from "./CookieBar.styles";

const CookieBar = (props) => {
  //PROPS
  const { translations } = props;
  //PROPS

  //COOKIES
  const [cookies, setCookie] = useCookies(["hasCookiesDEV"]);
  //COOKIES

  //FUNCS
  const handleCookie = () => {
    setCookie("hasCookiesDEV", "true", { path: "/" });
  };
  //FUNCS

  //DEFS
  const lang = (translations && translations[0]?.language.code) ?? "PT";
  //DEFS

  if (cookies.hasCookiesDEV !== "true") {
    return (
      <CookieContainer>
        <div className="container">
          <p>
            {copy.cookies[lang]}{" "}
            <Link
              to={
                lang === "EN"
                  ? "/politica-de-privacidade/"
                  : "/en/privacy-policy"
              }
            >
              {copy["cookies-here"][lang]}
            </Link>
            .
          </p>
          <button onClick={() => handleCookie()}>
            {copy["cookies-accept"][lang]}
          </button>
        </div>
      </CookieContainer>
    );
  } else {
    return null;
  }
};

export default CookieBar;
