import styled from 'styled-components';

import { black, white, grey, blue } from 'theme/colors';
import { toRem, breakpoints } from 'utils/mixins';
import { space, zIndex } from 'theme/sizes';
import { h3 } from 'theme/typography';

export const Wrapper = styled.nav`
  ${zIndex.top};
  overflow: visible;
  background-color: ${white.default};
  height: 100vh;
  width: 100vw;
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateY(-100%);

  @media ${breakpoints.laptop} {
    background-color: transparent;
    height: auto;
    width: auto;
    position: relative;
    left: unset;
    top: unset;
    display: block;
    align-items: unset;
    justify-content: unset;
    margin-left: ${space.m};
    transform: translateY(0) !important;

    .menu {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      height: ${space.header};
    }
  }
`;

export const NavLi = styled.li`
  line-height: 0;
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: ${space.s};
  opacity: 0;
  transform: ${`translateX(${space.xxs})`};

  ul {
    transition: 120ms;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: ${space.s};
    margin-top: ${space.s};
  }

  &:hover ul {
    transform: translateX(-16px) translateY(0);
    opacity: 1;
    pointer-events: unset;
  }

  a {
    color: ${black.default};
    text-transform: uppercase;
    position: relative;
    ${h3}

    &::before {
      content: '';
      position: absolute;
      width: 0;
      height: ${toRem(1)};
      display: block;
      bottom: 0;
      right: 0;
      transition: 0.4s ease;
      background-color: ${black.default};
    }

    &:hover {
      &::before {
        width: 100%;
        left: 0;
      }
    }
  }

  @media ${breakpoints.laptop} {
    opacity: 1 !important;
    transform: unset !important;
    margin-left: ${space.s};
    margin-bottom: 0;
    flex-direction: row;
    justify-content: flex-start;

    ul {
      position: absolute;
      padding: 16px;
      align-items: flex-start;
      background-color: ${white.darker};
      top: 100%;
      margin-top: unset;
      z-index: 20;
      transform: translateX(-16px) translateY(36px);
      opacity: 0;
      pointer-events: none;
      gap: 12px;
    }

    a {
      text-align: left;
      font-size: ${toRem(12)};
      line-height: ${toRem(14)};
      color: ${black.default};

      &::before {
        background-color: ${black.default};
      }
    }
  }
`;

export const Sub = styled.div`
  overflow: visible;
  position: fixed;
  top: ${toRem(73)};
  left: 0;
  opacity: 1;
  transition: 250ms;
  width: 100vw;
  background-color: ${black.default};
  align-items: center;
  justify-content: center;
  display: none;
  pointer-events: all;

  ul {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;

    li {
      padding: ${space.xs} 0;
      position: relative;
      margin-left: ${space.s};

      a {
        color: ${white.default} !important;
        text-align: left;
        font-size: ${toRem(12)};
        line-height: ${toRem(14)};

        &::before {
          background-color: ${white.default} !important;
        }
      }

      .sub__inner {
        position: absolute;
        top: 100%;
        opacity: 0;
        transition: 250ms;
        width: clamp(25vw, 700px, 50vw);
        background-color: ${white.default};
        pointer-events: none;
        box-shadow: rgba(100, 100, 111, 0.2) 0px ${toRem(7)} ${toRem(29)} 0px;
        display: flex;
        align-items: stretch;
        justify-content: space-between;
        height: 40vh;
        overflow: hidden;

        ul {
          flex-grow: 1;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          min-width: 140px;
          width: auto;
          position: relative;

          li {
            width: auto;
            padding: ${space.xs} 0;

            a {
              color: ${black.default} !important;

              &::before {
                background-color: ${black.default} !important;
              }
            }
          }
        }

        .sub__image {
          position: relative;
          flex-grow: 1;
          height: 40vh;
          height: auto;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;

          img {
            display: block;
            object-fit: cover;
            height: 100%;
          }
        }
      }

      &:hover .sub__inner {
        opacity: 1;
        pointer-events: all;
      }
    }
  }

  &:hover {
    opacity: 1;
    pointer-events: all;
  }

  @media ${breakpoints.laptop} {
    display: flex;
    transform: translateX(-204px);

    ul {
      li {
        ul {
          display: flex;
        }
      }
    }
  }
`;
