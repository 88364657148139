import React from 'react';

import Lottie from 'react-lottie';
import gsap from 'gsap';

import { Wrapper } from './Loader.styles';

import * as animationData from './loader.json';

const Loader = () => {
  //DEFS
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  //DEFS

  //FUNCS
  const onComplete = () => {
    gsap
      .timeline()
      .to('#loader', {
        duration: 0.5,
        css: { opacity: 0, y: 10 },
      })
      .to('#loader-wrapper', {
        duration: 0.5,
        css: { opacity: 0 },
      })
      .to('#loader-wrapper', {
        duration: 0.0,
        css: { display: 'none' },
      })
      .to('body', {
        duration: 0,
        delay: 0,
        css: { overflowY: 'unset' },
      });
  };
  //FUNCS

  return (
    <Wrapper id="loader-wrapper">
      <div id="loader">
        <Lottie
          options={defaultOptions}
          eventListeners={[
            {
              eventName: 'complete',
              callback: onComplete,
            },
          ]}
        />
      </div>
    </Wrapper>
  );
};

export default Loader;
