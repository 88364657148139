export const white = {
  default: '#ffffff',
  darker: '#f5f5f5',
  grey: '#ececec',
};

export const black = {
  default: '#231f20',
};

export const grey = {
  default: '#ececec',
  darker: '#44413d',
};

export const blue = {
  default: '#231f20',
};

export const red = {
  default: '#E07167',
};
