import React, { useEffect, useState } from 'react';

import gsap from 'gsap';
import { AnimatePresence } from 'framer-motion';
import WhatsAppWidget from 'react-whatsapp-chat-widget';
import 'react-whatsapp-chat-widget/index.css';

import { GlobalStyles } from 'theme/global';

import CookieBar from 'components/cookieBar';
import Header from 'components/header';
import Footer from 'components/footer';
import CreateHead from 'components/createHeader';
import Loader from 'components/loader';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

//UTILITY
function debounce(fn, ms) {
  let timer;
  return () => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      timer = null;
      fn.apply(this, arguments);
    }, ms);
  };
}
//UTILITY

// Create a client
const queryClient = new QueryClient();

const Layout = (props) => {
  const { location } = props;
  //STATE

  const [isMenuOpen, setMenuOpen] = useState(false);
  const [dimensions, setDimension] = useState({
    height: '',
    width: '',
  });
  const [scroll, setScroll] = useState(false);
  //STATE

  //DEFS
  const seo = props.pageContext?.seo;
  const pageTranslation = props.pageContext?.translations?.[0]?.seo?.slug;
  //DEFS

  //EFFECT
  useEffect(() => {
    let ticking = false;

    const updateScrollDir = () => {
      const scrollY = window.pageYOffset;
      scrollY > 150 ? setScroll(true) : setScroll(false);
      ticking = false;

      return;
    };

    const onScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(updateScrollDir);
        ticking = true;
      }
    };

    if (props.pageContext?.template?.templateName === 'Homepage Template') {
      window.addEventListener('scroll', onScroll, true);
    } else {
      setScroll(true);
    }

    const cleanTransform = () => {
      if (
        !document?.querySelector('.main')?.children?.[1] ||
        !document?.querySelector('.main')?.children?.[1]?.style.transform
      ) {
        setTimeout(() => {
          cleanTransform();
        }, 800);
      } else {
        document.querySelector('.main').children[1].style.transform = 'none';
      }
    };

    cleanTransform();

    return () => {
      window.removeEventListener('scroll', onScroll, true);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    gsap.to('body', {
      duration: 0,
      css: { visibility: 'visible', opacity: 1 },
    });

    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    const debouncedHandleResize = debounce(function handleResize() {
      setDimension({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    }, 100);

    window.addEventListener('resize', debouncedHandleResize);

    setDimension({
      height: window.innerHeight,
      width: window.innerWidth,
    });

    return () => {
      window.removeEventListener('resize', debouncedHandleResize);
    };
  }, []);

  //EFFECT

  //CHILDREN MODIFIER
  const childrenWithProps = React.Children.map(props.children, (child) => {
    return React.cloneElement(child, {
      dimensions: dimensions,
      lang: props.pageContext?.language?.code,
    });
  });
  //CHILDREN MODIFIER

  return (
    <>
      <GlobalStyles />
      <QueryClientProvider client={queryClient}>
        <CreateHead
          title={seo?.seoTitle}
          description={seo?.seoDescription}
          image={seo?.seoImage}
          lang={props.pageContext?.language?.code}
        />
        <Header
          pageTranslation={pageTranslation}
          scroll={scroll}
          dimensions={dimensions}
          lang={props.pageContext?.language?.code}
          isMenuOpen={isMenuOpen}
          setMenuOpen={setMenuOpen}
        />
        <main className="main">
          <Loader />
          <AnimatePresence>{childrenWithProps}</AnimatePresence>
        </main>
        {/* <WhatsAppWidget
          phoneNo="351916197017"
          autoOpen={true}
          position="right"
          headerIcon="/android-chrome-192x192.png"
          chatPersonName="Carlos Dias"
          headerTitle="Industrilar"
          autoOpenTimer={5000}
          messageBox={true}
          widgetWidth="300px"
          widgetWidthMobile="260px"
          chatMessage={<>Em que posso ajudar?</>}
          placeholder="A sua mensagem..."
          btnTxt="Começar conversa"
        /> */}

        <Footer lang={props.pageContext?.language?.code} />
        <CookieBar translations={null} />
      </QueryClientProvider>
    </>
  );
};

export default Layout;
