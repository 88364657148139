import { css } from 'styled-components';
import { toRem, breakpoints } from 'utils/mixins';

export const h1 = css`
  font-family: 'Gilda Display', serif;
  font-weight: 400;
  font-size: ${toRem(34)};
  line-height: ${toRem(39)};
  margin: 0;
  margin-bottom: ${toRem(20)};
  letter-spacing: 0.1px;

  @media ${breakpoints.laptop} {
    font-size: ${toRem(56)};
    line-height: ${toRem(62)};
    letter-spacing: 0.2px;
  }
`;

export const h2 = css`
  font-family: 'Gilda Display', serif;
  font-weight: 400;
  font-size: ${toRem(28)};
  line-height: ${toRem(34)};
  letter-spacing: 0.1px;
  margin-bottom: ${toRem(12)};

  @media ${breakpoints.laptop} {
    font-size: ${toRem(44)};
    line-height: ${toRem(50)};
    letter-spacing: 0.2px;
  }
`;

export const h3 = css`
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
  font-size: ${toRem(22)};
  line-height: ${toRem(33)};
  letter-spacing: 0.1px;

  @media ${breakpoints.laptop} {
    font-size: ${toRem(20)};
    line-height: ${toRem(30)};
  }
`;

export const h4 = css`
  font-family: 'Gilda Display', serif;
  font-weight: 400;
  font-size: ${toRem(16)};
  line-height: ${toRem(27)};
  letter-spacing: 0.1px;

  @media ${breakpoints.laptop} {
    font-size: ${toRem(18)};
    line-height: ${toRem(24)};
  }
`;

export const p = css`
  font-family: 'Open Sans', sans-serif;
  font-size: ${toRem(16)};
  line-height: ${toRem(21)};
  letter-spacing: 0.1px;
`;
