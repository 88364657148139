import styled from 'styled-components';

import { white } from 'theme/colors';
import { space } from 'theme/sizes';
import { breakpoints } from 'utils/mixins';

export const Wrapper = styled.footer`
  padding: ${space.xs};
  background-color: ${white.default};
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;

  .copyright {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .util-links {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    button {
      padding: 8px;
    }
  }

  .images {
    margin-left: 16px;

    img {
      width: ${space.xl};
      height: ${space.xl};
      object-fit: contain;
    }
  }

  .utils {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      transform: translateY(4px);
    }
  }

  a {
    transform: translateX(-6px);
  }

  p {
    padding: 15px 20px 15px 20px;
  }

  img {
    width: 100px;
    transform: translateX(-6px);
  }

  @media ${breakpoints.mobileonly} {
    display: block;
    text-align: center;
    align-items: center;
    justify-content: center;

    div {
      padding: ${space.xxs};
    }

    img {
      margin: auto;
    }
  }
`;
