import React from 'react';

import { Link } from 'gatsby';

import { Wrapper } from './Footer.styles';

import copy from 'data/copy';

const Footer = (props) => {
  //PROPS
  const { lang } = props;
  //PROPS

  const date = new Date();

  return (
    <Wrapper>
      <div className='util-links'>
        <p>Copyright © {date.getFullYear()} Industrilar, Lda.</p>
      </div>
      <div className="copyright">
        <div className="images">
          <img src="/images/pme_lider.jpg" alt="PME Lider" />
          <img src="/images/portugalsoueu.jpg" alt="Portugal sou eu" />
          <img src="/images/caixa_top.jpeg" alt="Caixa top" />
          <img src="/images/scoring.png" alt="Scoring" />
        </div>

      </div>
      <div className="utils">
        <a href="https://www.livroreclamacoes.pt/inicio/">
          <img src="/livrodereclamaçoes.png" alt="Livro de Reclamações" />
        </a>
        <Link
          className="button button--secondary"
          to={lang === 'PT' ? '/politica-de-privacidade' : '/en/privacy-policy'}
        >
          {copy.privacy[lang]}
        </Link>
      </div>
    </Wrapper>
  );
};

export default Footer;
