import React, { useEffect } from 'react';

import gsap from 'gsap';

import { Wrapper, Logo, Toggle } from './Header.styles';
import Nav from './nav';
import SecondNav from './secondNav';
import { Link } from 'gatsby';

const Header = (props) => {
  //PROPS
  const { dimensions, lang, isMenuOpen, setMenuOpen, pageTranslation } = props;
  //PROPS

  //ANIMS
  const tl = gsap.timeline();

  const openMenu = () => {
    tl.to('.navContainer', {
      duration: 0.4,
      css: {
        translateY: 0,
      },
    })
      .to('.span ', {
        duration: 0.22,
        delay: -0.4,
        css: { top: 7 },
      })
      .to('.span--top', {
        duration: 0.22,
        rotation: 45,
      })
      .to('.span--bottom', {
        duration: 0.22,
        delay: -0.22,
        rotation: -45,
      })
      .to('.span--center', {
        duration: 0.22,
        delay: -0.22,
        opacity: 0,
      })
      .to('.nav-column', {
        opacity: 1,
        x: 0,
        duration: 1,
        delay: -0.4,
        stagger: 0.2,
      })
      .to('.secondNav', {
        duration: 0.2,
        delay: -2,
        y: 0,
        opacity: 1,
      });
  };

  const closeMenu = () => {
    tl.to('.nav-column', {
      opacity: 0,
      x: 10,
      delay: -0.2,
      duration: 1,
      stagger: 0.2,
    })
      .to('.span', {
        duration: 0.22,
        rotation: 0,
      })
      .to('.span--top', {
        duration: 0.22,
        css: { top: 0 },
      })
      .to('.span--bottom', {
        duration: 0.22,
        delay: -0.22,
        css: { top: 14 },
      })
      .to('.span--center', {
        duration: 0.22,
        delay: -0.22,
        opacity: 1,
      })
      .to('.secondNav', {
        duration: 0.2,
        opacity: 0,
        delay: -1,
        y: 400,
      })
      .to('.navContainer', {
        duration: 0.6,
        delay: -1,
        css: {
          translateY: '-100%',
        },
      });
  };
  //ANIMS

  //EFFECTS
  useEffect(() => {
    if (isMenuOpen && dimensions.width) {
      openMenu();
    } else if (dimensions.width) {
      closeMenu();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMenuOpen]);

  useEffect(() => {
    if (dimensions.width >= 1024) {
      setMenuOpen(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dimensions]);
  //EFFECTS

  return (
    <Wrapper id="header">
      <Logo>
        <Link to={lang === 'PT' ? '/' : '/en/'}>
          <img src="/images/logo.svg" alt="Industrilar logo" />
        </Link>
      </Logo>
      <Nav lang={lang} setMenuOpen={setMenuOpen} />
      <SecondNav
        lang={lang}
        setMenuOpen={setMenuOpen}
        pageTranslation={pageTranslation}
      />
      <Toggle
        onClick={() => {
          setMenuOpen(!isMenuOpen);
        }}
      >
        <span className="span span--top"></span>
        <span className="span span--center"></span>
        <span className="span span--bottom"></span>
      </Toggle>
    </Wrapper>
  );
};

export default Header;
