import { css } from 'styled-components';

import { toRem } from 'utils/mixins';

export const space = {
  tiny: toRem(4),
  xxs: toRem(8),
  xs: toRem(16),
  s: toRem(24),
  m: toRem(36),
  l: toRem(54),
  xl: toRem(72),
  xxl: toRem(144),
  big: toRem(196),
  // big: toRem(152),
  // xl: toRem(240),
  //
  header: toRem(72),
};

export const fontSize = {
  xs: toRem(12),
  s: toRem(16),
  l: toRem(26),
  xl: toRem(40),
};

export const container = {
  default: toRem(1440),
  small: toRem(1290),
  xsmall: toRem(960),
  xxsmall: toRem(720),
};

export const zIndex = {
  default: css`
    z-index: 1;
  `,
  above: css`
    z-index: 2;
  `,
  top: css`
    z-index: 3;
  `,
  under: css`
    z-index: -1;
  `,
  extreme: css`
    z-index: 4;
  `,
};
