import styled from 'styled-components';

import { black } from 'theme/colors';
import { toRem, breakpoints } from 'utils/mixins';
import { space, zIndex } from 'theme/sizes';

export const Wrapper = styled.div`
  position: fixed;
  bottom: 0;
  transform: translateY(100%);
  //z-index: 21;
  ${zIndex.extreme};
  left: 0;
  width: 100vw;
  height: ${space.xl};
  padding-bottom: ${space.l};
  align-items: center;
  justify-content: center;
  opacity: 0;
  display: flex;

  .menu {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
  }

  @media ${breakpoints.laptop} {
    opacity: 1 !important;
    position: relative;
    padding-bottom: 0;
    top: unset;
    left: unset;
    width: unset;
    height: unset;
    transform: none !important;
    display: flex;
    align-items: center;
    justify-content: unset;
    margin-left: auto;
  }
`;

export const NavLi = styled.li`
  padding: ${space.xs};
  text-align: center;

  a,
  button {
    color: ${black.default};
    text-transform: uppercase;
    line-height: normal;
    position: relative;
    font-weight: 300;
    font-size: ${toRem(12)};
    line-height: ${toRem(14)};

    &::before {
      content: '';
      position: absolute;
      width: 0;
      height: ${toRem(1)};
      display: block;
      bottom: 0;
      right: 0;
      transition: 0.4s ease;
      background-color: ${black.default};
    }

    &:hover {
      &::before {
        width: 100%;
        left: 0;
      }
    }
  }

  @media ${breakpoints.laptop} {
    padding: 0;
    margin-left: ${space.s};
    opacity: 1 !important;

    a,
    button {
      font-size: ${toRem(12)};
      line-height: ${toRem(14)};

      &::before {
        background-color: ${black.default};
      }
    }
  }
`;

export const Lang = styled.ul`
  position: absolute;
  bottom: 0;
  right: 0;
  padding: ${space.xs};
  //z-index: 21;
  ${zIndex.extreme};
  display: flex;
  align-items: center;

  li {
    position: relative;
    span,
    a {
      display: block;
      color: ${black.default};
      text-transform: uppercase;
      line-height: normal;
      font-weight: 300;
      font-size: ${toRem(12)};
      line-height: ${toRem(14)};
      padding: ${space.xxs};
    }

    &::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      transform: translateY(14px);
      width: 100%;
      height: ${toRem(5)};
      background-color: transparent;
    }
  }

  .active {
    &::after {
      background-color: ${black.default};
    }
  }

  @media ${breakpoints.laptop} {
    position: relative;
    height: ${space.header};
    padding: 0;

    li {
      span,
      a {
        font-size: ${toRem(12)};
        line-height: ${toRem(14)};
      }

      &::after {
        top: 0;
        bottom: unset;
        transform: translateY(-28px);
      }
    }
  }
`;
