import React from 'react';
import { Helmet } from 'react-helmet';

const CreateHead = (props) => {
  const headInfo = {
    title: props.title ? props.title + ' | Industrilar' : 'Industrilar',
    description: props.description
      ? props.description
      : 'Design é função, é forma.',
    url: props.url ? props.url : 'https://industrilar.com',
    image: props.img ? props.img : '/banner.png',
  };

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: props.lang?.toLowerCase() ?? 'PT',
        }}
      >
        <title>{headInfo.title}</title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, user-scalable=no"
        />
        <meta name="description" content={headInfo.description} />
        <link
          rel="apple-touch-icon"
          sizes="57x57"
          href="/apple-icon-57x57.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="60x60"
          href="/apple-icon-60x60.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="72x72"
          href="/apple-icon-72x72.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="76x76"
          href="/apple-icon-76x76.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="114x114"
          href="/apple-icon-114x114.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="120x120"
          href="/apple-icon-120x120.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="144x144"
          href="/apple-icon-144x144.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="152x152"
          href="/apple-icon-152x152.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-icon-180x180.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="192x192"
          href="/android-icon-192x192.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="96x96"
          href="/favicon-96x96.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/manifest.json" />
        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="msapplication-TileImage" content="/ms-icon-144x144.png" />
        <meta name="theme-color" content="#ffffff" />
        <meta property="og:url" content={headInfo.url} />
        <meta property="og:title" content={headInfo.title} />
        <meta property="og:description" content={headInfo.description} />
        <link rel="image_src" href={headInfo.image}></link>
        <meta property="og:image" content={headInfo.image} />
        <meta property="og:site_name" content={headInfo.title} />
        <meta name="twitter:title" content={headInfo.title} />
        <meta name="twitter:description" content={headInfo.description} />
        <meta name="twitter:image" content={headInfo.image} />
        <meta name="twitter:card" content="summary_large_image"></meta>
        <meta name="twitter:image:alt" content={headInfo.title}></meta>
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=Gilda+Display&family=Open+Sans:wght@300;400;700&display=swap"
          rel="stylesheet"
        />
        <link
          rel="stylesheet"
          type="text/css"
          charset="UTF-8"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
        />
      </Helmet>
      {props.children}
    </>
  );
};
export default CreateHead;
