import styled from 'styled-components';

import { toRem } from 'utils/mixins';
import { white } from '../../theme/colors';

export const Wrapper = styled.div`
  top: 0;
  left: 0;
  display: block;
  position: fixed;
  z-index: 100;
  width: 100%;
  height: 100vh;
  background-color: ${white.darker};
  display: flex;
  align-items: center;
  justify-content: center;

  #loader {
    width: ${toRem(300)};
    object-fit: contain;
  }
`;
